import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetRegionsQuery, useDeleteRegionByIdMutation } from '../api/region';
import MDTypography from 'components/material/MDTypography';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MDButton from 'components/material/MDButton';
import { toast } from 'react-toastify';

export const useRegions = () => {
  const { data, isLoading } = useGetRegionsQuery();
  const [deleteRegion, { isLoading: isDeleting }] = useDeleteRegionByIdMutation();
  const navigate = useNavigate();
  const [deleteDialog, setDeleteDialog] = useState({ open: false, region: null });

  const handleEdit = (region) => {
    navigate(`/dashboard/regions/edit/${region.id}`);
  };

  const handleDeleteClick = (region) => {
    setDeleteDialog({ open: true, region });
  };

  const handleDeleteConfirm = async () => {
    try {
      await deleteRegion(deleteDialog.region.id).unwrap();
      toast.success('Regiunea a fost ștearsă cu succes!');
    } catch (error) {
      toast.error('Regiunea nu poate fi stearsa deoarece are membrii asociati.');
    } finally {
      setDeleteDialog({ open: false, region: null });
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialog({ open: false, region: null });
  };

  const DeleteConfirmationDialog = () => (
    <Dialog open={deleteDialog.open} onClose={handleDeleteCancel}>
      <DialogTitle>Confirmare ștergere</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Sunteți sigur că doriți să ștergeți regiunea "{deleteDialog.region?.name}"?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <MDButton onClick={handleDeleteCancel} color="primary">
          Anulează
        </MDButton>
        <MDButton onClick={handleDeleteConfirm} color="error" loading={isDeleting}>
          Șterge
        </MDButton>
      </DialogActions>
    </Dialog>
  );

  return {
    isLoading,
    DeleteConfirmationDialog,
    columns: [
      { Header: 'id', accessor: 'id', align: 'left' },
      { Header: 'nume', accessor: 'name', align: 'left' },
      { Header: 'nume de adresa', accessor: 'fullName' },
      { Header: 'membrii', accessor: 'userCount', align: 'left' },
      { Header: 'actiuni', accessor: 'actions', align: 'left' },
    ],
    rows: isLoading
      ? []
      : data.map((region, index) => ({
          entry: {
            id: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {index + 1}
              </MDTypography>
            ),
            name: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {region.name}
              </MDTypography>
            ),
            fullName: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {region.fullName || region.name}
              </MDTypography>
            ),
            userCount: (
              <MDTypography variant="caption" color="text" fontWeight="medium">
                {region.usercount}
              </MDTypography>
            ),
            actions: (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconButton color={'primary'} onClick={() => handleEdit(region)}>
                  <Icon>edit</Icon>
                </IconButton>

                <IconButton color={'error'} onClick={() => handleDeleteClick(region)}>
                  <Icon>delete</Icon>
                </IconButton>
              </div>
            ),
          },
        })),
  };
};
