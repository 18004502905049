import axios from 'axios';
import { useSelector } from 'react-redux';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import MDTypography from 'components/material/MDTypography';
import { useGetAddressesQuery } from 'api/registry';
import { downloadBuffer } from 'services/downloadFile';

export const useAddresses = () => {
  const { data, isLoading } = useGetAddressesQuery();
  const { access_token } = useSelector((state) => state.auth);

  const downloadAddress = async (address) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/registry/address/${address.id}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          Authorization: `Bearer ${access_token}`,
        },
      }
    );

    downloadBuffer(data, 'pdf', address.s3Key);
  };

  return {
    isLoading,
    columns: [
      { Header: 'id', accessor: 'id', align: 'left' },
      { Header: 'nume', accessor: 'name', align: 'left' },
      { Header: 'regiune', accessor: 'region', align: 'left' },
      { Header: 'descarca', accessor: 'download', align: 'left' },
    ],
    rows: data?.map((address, index) => ({
      entry: {
        id: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {index + 1}
          </MDTypography>
        ),
        name: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {address.s3Key}
          </MDTypography>
        ),
        region: (
          <MDTypography variant="caption" color="text" fontWeight="medium">
            {address.region?.name || '-'}
          </MDTypography>
        ),
        download: (
          <IconButton
            color={'primary'}
            onClick={() => {
              downloadAddress(address);
            }}
          >
            <Icon>download</Icon>
          </IconButton>
        ),
      },
    })),
  };
};
