export const endpoints = {
  login: '/auth/login',
  getUserProfile: '/auth/check',
  getUsers: '/users',
  createUser: '/users/create',
  updateUser: '/users/update',
  deleteUser: '/users/delete',
  getPolls: '/polls',
  createPoll: '/polls/create',
  updatePoll: '/polls/update',
  deletePoll: '/polls/delete',
  votePoll: '/polls/vote',
  getRegions: '/region',
  createRegion: '/region/create',
  updateRegion: '/region/update',
  deleteRegion: (id) => `/region/${id}`,
  getRegistry: '/registry',
  createRegistry: '/registry/create',
  updateRegistry: '/registry/update',
  deleteRegistry: '/registry/delete',
  getAddresses: '/addresses',
  createAddress: '/addresses/create',
  updateAddress: '/addresses/update',
  deleteAddress: '/addresses/delete',
  getCounties: '/counties',
  getAccessibleCounties: '/counties/accessible',
};
