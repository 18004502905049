import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  users: [],
  counties: [],
  currentUser: null,
  // filters
  searchTerm: '',
  searchRegion: 0,
  searchCounty: 0,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers(state, action) {
      const { payload } = action;
      state.users = payload;
    },

    setCurrentUser(state, action) {
      state.currentUser = action.payload;
    },

    setCounties(state, action) {
      const { payload } = action;
      state.counties = payload.data;
    },

    setSearchTerm(state, action) {
      const { payload } = action;
      state.searchTerm = payload;
    },

    setSearchRegion(state, action) {
      state.searchRegion = action.payload;
    },

    setCountySearch(state, action) {
      state.searchCounty = action.payload;
    },
  },
});

export const {
  setUsers,
  setCounties,
  setCurrentUser,
  setSearchTerm,
  setSearchRegion,
  setCountySearch,
} = userSlice.actions;

export default userSlice.reducer;
