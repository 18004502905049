import React, { useEffect, useState } from 'react';

import DashboardLayout from 'components/layouts/DashboardLayout';
import DashboardNavbar from 'components/navbar/DashboardNavbar/TopNavbar';
import BasicTable from 'components/common/Table';
import { useRegions } from 'hooks/useRegions';
import MDBox from 'components/material/MDBox';
import MDInput from 'components/material/MDInput';
import MDButton from 'components/material/MDButton';
import { useCreateRegionMutation } from 'api/region';
import { toast } from 'react-toastify';

const RegrionsScreen = () => {
  const [region, setRegion] = useState({ name: '' });
  const { columns, rows, isLoading, DeleteConfirmationDialog } = useRegions();
  const [createRegion, { isSuccess, isError, isLoading: loadingCreate }] =
    useCreateRegionMutation();

  const handleSubmit = () => {
    createRegion(region);
  };

  useEffect(() => {
    if (isError) return toast.error('A aparut o problema, va rugam sa incercati mai tarziu.');
    if (isSuccess) return toast.success('Regiuna a fost creeata cu succes!');
  }, [isSuccess, isError]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <DeleteConfirmationDialog />
      <MDBox py={3} style={{ display: 'flex' }}>
        <MDBox mb={3} sx={{ flex: 1, marginRight: '12px' }}>
          <MDInput
            label="Nume"
            name="name"
            value={region.name}
            onChange={(e) => {
              setRegion({ ...region, name: e.target.value });
            }}
            fullWidth
          />
        </MDBox>
        <MDBox>
          <MDButton loading={loadingCreate} ariant="gradient" color="info" onClick={handleSubmit}>
            Salveaza
          </MDButton>
        </MDBox>
      </MDBox>
      {isLoading ? <p>Loading...</p> : <BasicTable pageSize={100} rows={rows} columns={columns} />}
    </DashboardLayout>
  );
};

export default RegrionsScreen;
