import { baseApi } from 'api';
import { endpoints } from './endpoints';


const regionApiSlice = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getRegions: builder.query({
      query: () => '/region',
      providesTags: ['Region'],
    }),

    getRegionById: builder.query({
      query: (id) => `/region/${id}`,
      providesTags: ['Region'],
    }),

    createRegion: builder.mutation({
      query: (data) => ({
        url: '/region',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Region'],
    }),

    updateRegionById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/region/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Region'],
    }),

    deleteRegionById: builder.mutation({
      query: (id) => ({
        url: endpoints.deleteRegion(id),
        method: 'DELETE',
      }),
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            regionApiSlice.util.updateQueryData('getRegions', undefined, (draft) => {
              return draft.filter((region) => region.id !== id);
            })
          );
        } catch (e) {
          console.log('Error ', e);
        }
      },
    }),
  }),
});

export const {
  useGetRegionsQuery,
  useGetRegionByIdQuery,
  useCreateRegionMutation,
  useUpdateRegionByIdMutation,
  useDeleteRegionByIdMutation,
} = regionApiSlice;
